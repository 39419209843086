import './Forecast.scss';
import { iForecast } from './interfaces/Forecast';
import ForecastIcon from './ForecastIcon';

interface Props {
  forecast?: iForecast;
}

function Forecast(props: Props) {
  return (
    <div className="Forecast">
      <ForecastIcon code={props.forecast?.conditionCode} text={props.forecast?.conditionText} />
      <div className="ForecastMain">
        <span>{props.forecast?.name}</span>
        <span>{props.forecast?.temperature.average}&deg;<span className="unit">{props.forecast?.temperatureUnit}</span></span>
      </div>
      <div className="ForecastExtras">
        <div className="temperature">
          <span className="icon"><img src="/icons/temperature.svg" alt="Temperature" /></span>
          <span>{props.forecast?.temperature.minimum}&deg;/{props.forecast?.temperature.maximum}&deg;</span>
        </div>
        <div className="precipitation">
          <span className="icon">
            <img src={`/icons/rain.svg`} alt="rain" />
          </span>
          <span>{props.forecast?.precipitationChance}%</span>
        </div>
      </div>
    </div>
  );
}

export default Forecast;