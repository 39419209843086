/**
 * Capitalize a string
 * @param input 
 */
export const capitalize = (input: string): string => {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

/**
 * Try to determine a country based on a locale string
 * e.g. `en-US` will return `us` and `nl-NL` will return `nl`
 * @param locale 
 */
export const getUserCountry = (locale: string): string => {
  const parts = locale.split('-');
  if (parts.length === 1) return '';
  return parts[1].toLowerCase();
}