import axios from "axios";

function paramValidator(token: string, magazineId: string | number) {
    if (!token) throw new Error("no token");
    if (!magazineId) throw new Error("no magazineId");
}

export const getWeather = async (token: string, magazine: string, days: number): Promise<any>  => {
    try {      
        paramValidator(token, magazine);
        const {data} = await axios.get(`/api/weather/${days}`, {
                headers: {
                    Authorization: token,
                    "x-magazine-id": magazine,
                },
        })
        return data;
    } catch (error) {
        throw error;
    }
}