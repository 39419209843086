import React from 'react';

interface Props { 
  code?: number;
  text?: string;
}

function ForecastIcon(props: Props) {
  const code = props.code || 1000;
  const text = props.text || 'Not enough data';

  const iconMap: any = {
    1000: 'sunny',
    1003: 'partly-cloudy',
    1006: 'cloudy',
    1009: 'cloudy',
    1030: 'cloudy',
    1063: 'patchy-rain',
    1066: 'snowy',
    1069: 'snowy',
    1072: 'patchy-rain',
    1087: 'stormy',
    1114: 'snowy',
    1117: 'snowy',
    1135: 'cloudy',
    1147: 'cloudy',
    1150: 'patchy-rain',
    1153: 'patchy-rain',
    1168: 'patchy-rain',
    1171: 'rainy',
    1180: 'patchy-rain',
    1183: 'rainy',
    1186: 'rainy',
    1189: 'rainy',
    1192: 'rainy',
    1195: 'rainy',
    1198: 'rainy',
    1201: 'rainy',
    1204: 'snowy',
    1207: 'snowy',
    1210: 'snowy',
    1213: 'snowy',
    1216: 'snowy',
    1219: 'snowy',
    1222: 'snowy',
    1225: 'snowy',
    1237: 'snowy',
    1240: 'patchy-rain',
    1243: 'rainy',
    1246: 'rainy',
    1249: 'snowy',
    1252: 'snowy',
    1255: 'snowy',
    1258: 'snowy',
    1261: 'snowy',
    1264: 'snowy',
    1273: 'stormy',
    1276: 'stormy',
    1279: 'snowy',
    1282: 'snowy',
  }

  return (
    <div className="ForecastIcon">
      <img src={`/weather-icons/${iconMap[code]}.svg`} alt={text} />
    </div>
  );
}

export default ForecastIcon;