import { useEffect, useState } from 'react';
import { capitalize } from './utils';
import Forecast from './Forecast';
import { iForecast } from './interfaces/Forecast';
import { useTranslation } from 'react-i18next';
import { useTheme } from './hooks/theme';
import './services/i18n';
import './App.scss';
import * as Bowser from 'bowser';
import { getWeather } from './services/Api';

const days = 2;

const formatForecast = (name: string, forecast: any, unit: "c" | "f", current?: any): iForecast => {
  return {
    name,
    temperatureUnit: unit,
    temperature: {
      minimum: Number(forecast.day[`mintemp_${unit}`]).toFixed(0),
      average: Number(current ? current[`temp_${unit}`] : forecast.day[`avgtemp_${unit}`]).toFixed(0),
      maximum: Number(forecast.day[`maxtemp_${unit}`]).toFixed(0),
    },
    precipitationChance: forecast.day.daily_chance_of_rain,
    conditionCode: current ? current.condition.code : forecast.day.condition.code,
    conditionText: current ? current.condition.text : forecast.day.condition.text
  };
}

function App() {
  const [location, setLocation] = useState<string>('');
  const [forecastToday, setForecastToday] = useState<null | iForecast>(null);
  const [forecastTomorrow, setForecastTomorrow] = useState<null | iForecast>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);


  const { t, i18n } = useTranslation();
  const params = new URLSearchParams(window.location.search);
  const magazine: string = params.get('magazine') ?? '9999';
  const token: string = params.get('token') ?? '';
  const lang: string = params.get('lang') ?? 'en';
  const browser = Bowser.getParser(window.navigator.userAgent);

  /**
   * Set body class to only support dark mode on mobile
   */
  document.body.classList.add(browser.getPlatformType().toLowerCase());

  /**
   * Apply theming
   */
  useTheme(magazine);

  /**
   * This hook makes a request to the weather API for user specific
   * weather forecasts.
   */
  useEffect(() => {
    i18n.changeLanguage(lang);
    loadWeather();
  }, [i18n, lang]);

  async function loadWeather() {
    try {
      const fahrenheitCountries = ['us'];
      const { weather, tempUnit } = await getWeather(token, magazine, days);
      const unit = tempUnit ? tempUnit : (fahrenheitCountries.includes(lang) ? 'f' : 'c');
      setLocation(weather.location.name || '');
      setForecastToday(formatForecast(t('Today'), weather.forecast.forecastday[0], unit, weather.current))
      setForecastTomorrow(formatForecast(t('Tomorrow'), weather.forecast.forecastday[1], unit))
    } catch (error: any) {
      setError(true);
      setErrorMsg(error.message);
    }
  }

  if (error) {
    return <p>{errorMsg}</p>
  }

  /**
   * Render our view
   */
  return (
    <div className="App">
      <h1>{t('Weather in')} {capitalize(location)}</h1>
      <div className="forecast-container">
        {forecastToday !== null ? <Forecast forecast={forecastToday} /> : ''}
        {forecastTomorrow !== null ? <Forecast forecast={forecastTomorrow} /> : ''}
      </div>
    </div>
  );
}

export default App;
