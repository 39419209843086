import { useEffect } from "react";

/**
 * This hook applies a theme for the plugin if there is one 
 * defined for the magazine
 */
export const useTheme = (magazineId: string) => {
  useEffect(() => {
    let isMounted = true;

    const loadTheme = async () => {
      try {
        await import(`../themes/${magazineId}.theme.scss`);
        if (isMounted) {
          console.log('Loaded theme for magazine', magazineId);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Failed to load theme for magazine', magazineId, error);
        }
      }
    };

    loadTheme();

    return () => {
      isMounted = false;
    };
  }, [magazineId]);
};
